
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data() {
    return;
    {
      data: null;
    }
  },

  on: {
    pageInit: function () {
      let _user_logged = appUtilities.user_is_logged();

      if (_user_logged == false) {
        return false;
      }

      var self = this;
      var app = self.$app;
      console.log('routes /accepted-orders');
      $$('.toolbar').show();
      var _request_params = {
        'session_token': appCore.session_token
      }; //app.dialog.preloader();

      app.request({
        url: api_url + 'restaurant_past_orders.php',
        dataType: 'json',
        data: _request_params,
        method: 'POST',
        cache: false,
        crossDomain: true,
        success: function (ordersData) {
          console.log('response accepted-orders: ', ordersData); //app.dialog.close();

          self.$setState({
            data: ordersData
          });
          appCore.accepted_orders = ordersData;
          appCore.accepted_orders_count = ordersData.data ? ordersData.data.length : 0;
          appCore.accepted_orders_first_load = false;
        },
        error: function () {
          //app.dialog.close();
          app.dialog.alert(general_error_message, 'Attenzione', function () {}, "Errore");
        }
      });
    }
  },
  id: 'be7c667cfc',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=accepted-orders><!-- Navbar --><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=title>';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.data.data, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' Ordini accettati: ';
              r += Template7Helpers.js.call(ctx_3, "this.data.data.length", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' Nessun ordine accettato ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div><!-- Scrollable page content--><div class=page-content>';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<!--Ordini--><div class="list media-list"><ul>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.data.data, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><a href="/order/?order_id=';
              r += c(ctx_3.order_id, ctx_3);
              r += '" class="item-link item-content" style="background-color: ';
              r += c(ctx_3.bg_color, ctx_3);
              r += ';"><div class=item-media><img height=40px src=./static/images/cart-red.png></div><div class=item-inner><div class=item-title-row><div class=item-title><span style="font-weight: bold; color: #b11733;">Ordine numero:</span> <b>#';
              r += c(ctx_3.order_number, ctx_3);
              r += '</b></div></div><div class=item-text></div><div class=item-row><div class=item-title><b>Ordine per:</b> ';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.name, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div><div class=item-title></div></div><div class=item-row><div class=item-title><b>Giorno di consegna:</b> ';
              r += Template7Helpers.js.call(ctx_3, "appUtilities.convertUTCToLocalDate(this.order_date).format('DD/MM/YYYY')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div><div class=item-title></div></div><div class=item-row><div class=item-title><b>Orario consegna:</b> ';
              r += Template7Helpers.js_if.call(ctx_3, "this.time_limit_status == 'pre_order'", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += c(ctx_4.time_limit, ctx_4);
                  r += ' ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.time_limit_status != 'pre_order'", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' Il prima possibile ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div><div class=item-title></div></div><div class=item-row><div class=item-title><b>Orario di ritiro:</b> ';
              r += Template7Helpers.js.call(ctx_3, "appUtilities.convertUTCToLocalDate(this.restaurant_time_limit).format('HH:mm')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div><div class=item-title></div></div></div></a></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    