export default {
  id: '66a53bd1c9',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=login><div class="page-content login-screen-content"><div class=login-screen-title><img src=./static/images/jacknb_logo.png style="max-width: 40%;"></div><form><div class=list><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-label"><b>Email</b></div><div class=item-input-wrap><input id=signin-email type=text name=username placeholder="Email di registrazione"></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label"><b>Password</b></div><div class=item-input-wrap><input id=signin-password type=password name=password placeholder=Password></div></div></li></ul></div><div class=block><p class=row><a id=signin href=# class="button button-outline">Accedi</a><!-- <a id="signup" href="/signup/" class="button button-outline">Registrati</a> --></p></div></form><!--\n        <div class="list">\n          <ul>\n            <li class="item-content item-input">\n              <div class="item-inner">\n                <div class="item-title item-label">\n                  <a id="request-reset-password" href="#" style="font-weight: bold;">Password dimenticata? Clicca qui per reimpostarla!</a>\n                </div>\n              </div>\n            </li>\n          </ul>\n        </div>\n        --></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};