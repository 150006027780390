
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '7e4ab6ba7e',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=help><!-- Fixed/Dynamic Navbar --><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Indietro</span></a></div><div class=title>Aiuto</div><div class=right></div></div></div><!-- Scrollable page content--><div class=page-content><div class=card><div class=card-header><p>Hai bisogno di aiuto? Chiama il gestore della zona in cui ti trovi! <span style="color: #B23633;">Nota bene: il numero è riferito al luogo in cui ha sede il ristorante da cui hai ordinato</span></p></div><div class="card-content card-content-padding"><div class=list style="min-width: 100%;"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.data.data, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=tel:';
          r += c(ctx_2.area_manager_phone, ctx_2);
          r += ' class="item-link item-content external"><div class=item-inner><div class=item-title-row><div class=item-title>';
          r += c(ctx_2.area_name, ctx_2);
          r += '</div><div class=item-after></div></div><div class=item-subtitle>';
          r += c(ctx_2.area_manager_phone, ctx_2);
          r += '</div></div></a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    