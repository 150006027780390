
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: 'b6ec327388',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="item">\n		<!-- Fixed/Dynamic Navbar -->\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner sliding">\n				<!--BACK TO ORDERS-->\n				<div class="left">\n					<a href="/items/">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md"></span>\n					</a>\n				</div>\n				\n				<div class="title">Modifica prodotto</div>\n\n			</div>\n		</div>\n\n		<!-- Scrollable page content-->\n		<div class="page-content">\n\n			<div class="card card-outline">\n			\n				<!-- CATEGORIA -->\n\n				<div id="cat" class="card-header" style="font-weight: bold;">Seleziona categoria\n					<input type="text" placeholder="nessuna" ';
      r += Template7Helpers.js_if.call(ctx_1, "this.category!==null", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'value="';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.category, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' readonly="readonly" id="picker-cat"/>\n				</div>\n				<div class="card-content">\n					<div class="block">\n						<div id="picker-cat-container"></div>\n					</div>\n				</div>\n\n				<!-- SOTTOCATEGORIA -->\n\n				<div id="subcat" class="card-header" style="font-weight: bold;">Seleziona sottocategoria\n					<input type="text" placeholder="nessuna" ';
      r += Template7Helpers.js_if.call(ctx_1, "this.subcategory!==null", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'value="';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.subcategory, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' readonly="readonly" id="picker-subcat"/>\n				</div>\n				<div class="card-content">\n					<div class="block">\n						<div id="picker-subcat-container"></div>\n					</div>\n				</div>\n\n				<div class="list inline-labels no-hairlines-md">\n					<ul>\n\n						<!-- NOME -->\n\n						<li class="item-content item-input item-input-outline">\n							<div class="item-inner">\n								<div class="item-title item-label" style="font-weight: bold;">Nome</div>\n								<div class="item-input-wrap">\n									<input ';
      r += Template7Helpers.js_if.call(ctx_1, "typeof(this.item)!=='undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'value="';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.item.name, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' type="text" id="item-name"/>\n									<span class="input-clear-button"></span>\n								</div>\n							</div>\n						</li>\n\n						<!-- DESCRIZIONE -->\n						<li class="item-content item-input item-input-outline">\n							<div class="item-inner">\n								<div class="item-title item-label" style="font-weight: bold;">Descrizione</div>\n								<div class="item-input-wrap">\n									<textarea id="item-description">';
      r += Template7Helpers.js_if.call(ctx_1, "typeof(this.item)!=='undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.item.description, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</textarea>\n								  </div>\n\n							</div>\n						</li>\n\n						<!-- PREZZO -->\n						<li class="item-content item-input item-input-outline">\n							<div class="item-inner">\n								<div class="item-title item-label" style="font-weight: bold;">Prezzo</div>\n								<div class="item-input-wrap">\n									<input type="number" ';
      r += Template7Helpers.js_if.call(ctx_1, "typeof(this.item)!=='undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'value="';
          r += c(ctx_2.item.price, ctx_2);
          r += '"';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' id="item-price"/>\n								</div>\n							</div>\n						</li>\n\n						<!-- POSSIBILITà VARIANTI -->\n						<li>\n							<label class="item-checkbox item-content">\n								<input type="checkbox" id="is_customizable" ';
      r += Template7Helpers.js_if.call(ctx_1, "this.is_customizable", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'checked="checked"';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '/>\n								<i class="icon icon-checkbox"></i>\n								<div class="item-inner">\n									<div class="item-title">Aggiunta varianti</div>\n								</div>\n							</label>\n						</li>\n\n						<!-- VISIBILE IN DELIVERY -->\n						<li>\n							<label class="item-checkbox item-content">\n								<input type="checkbox" id="visible_for_delivery" ';
      r += Template7Helpers.js_if.call(ctx_1, "this.visible_for_delivery", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'checked="checked"';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '/>\n								<i class="icon icon-checkbox"></i>\n								<div class="item-inner">\n									<div class="item-title">Visibile in delivery</div>\n								</div>\n							</label>\n						</li>\n\n						<!-- VISIBILE IN MENU -->\n						<li>\n							<label class="item-checkbox item-content">\n								<input type="checkbox" id="visible_for_menu" ';
      r += Template7Helpers.js_if.call(ctx_1, "this.visible_for_menu", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'checked="checked"';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '/>\n								<i class="icon icon-checkbox"></i>\n								<div class="item-inner">\n									<div class="item-title">Visibile nel menu</div>\n								</div>\n							</label>\n						</li>\n\n						<!-- IMMAGINE -->\n						<li>\n							<label class="item-content">\n								<div class="item-inner">\n									<div class="item-title">Immagine</div>\n								</div>\n								<input id="item-image" type="file" accept="image/x-png,image/gif,image/jpeg"/>\n							</label>\n						</li>\n\n					</ul>\n				</div>\n\n\n			</div>\n\n			<!-- Azioni prodotto -->\n\n			<div class="card card-outline">\n				<div class="card-content card-content-padding">\n					';
      r += Template7Helpers.js_if.call(ctx_1, "this.type==='edit'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<div class="row padding">\n						<button id="save-item" class="col button button-small button-fill" style="min-height: 50px;">SALVA</button>\n					</div>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					';
      r += Template7Helpers.js_if.call(ctx_1, "this.type==='new'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<div class="row padding">\n						<button id="add-item" class="col button button-small button-fill" style="min-height: 50px;">SALVA</button>\n					</div>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n			</div>\n\n			';
      r += Template7Helpers.js_if.call(ctx_1, "this.type==='edit'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="card card-outline">\n				<div class="card-content card-content-padding">\n					<div class="row padding">\n						<button\n										class="col button button-small button-fill sheet-open"\n										style="min-height: 50px;"\n										data-sheet=".cancel-confirm-sheet"\n						>CANCELLA PRODOTTO</button>\n					</div>\n				</div>\n			</div>\n			';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n			<!-- Swipe to close sheet -->\n			<div class="sheet-modal cancel-confirm-sheet" style="height:auto; --f7-sheet-bg-color: #fff;">\n				<div class="sheet-modal-inner">\n					<div class="page-content">\n						<div class="block-title block-title-large">Attenzione!</div>\n						<div class="block">\n							<p><b>Sei sicuro di voler eliminare il prodotto?</b></p>\n							<a href="/menu/">\n								<div class="row padding">\n									<button id="cancel-confirm" class="col button button-small button-fill" style="min-height: 50px;">CONFERMA</button>\n								</div>\n							</a>\n\n							<div class="row padding">\n								<button id="cancel-stop" class="col button button-small button-fill" style="min-height: 50px;">ANNULLA</button>\n							</div>\n						</div>\n					</div>\n				</div>\n			</div>\n\n		</div>\n	</div>\n\n';
      return r;
    }(this);
  },

  style: `
.picker-columns
{
	font-size: 1.2em !important;
	text-align: left !important;
}
`,
  styleScoped: false
};
    